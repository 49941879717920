//inputs:
.toomarInputContainer {
    display: flex;
    flex-direction: column;
    direction: rtl;
    & > p:nth-child(1) {
        font-family: Aviny;
        font-size: 24px;
        line-height: 38px;
        margin: 0px 0px 6px;
    }
    & > input {
        width: 100%;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        font-size: 15px;
    }
    & > input:focus {
        outline: none;
    }
    & > input::placeholder {
        font-size: 15px;
    }
    & > textarea {
        width: 100%;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        font-size: 15px;
        resize: none;
    }
    & > textarea:focus {
        outline: none;
    }
    & > textarea::placeholder {
        font-size: 15px;
    }
    & > p:nth-child(3) {
        font-family: Aviny;
        font-size: 16px;
        line-height: 24px;
        margin: 0px 0px 0px;
        color: red;
    }
    
}

//follow us in socials:
.followUsInSocialsContainer {
    flex-direction: column!important;
    justify-content: center;
    align-items: center;
    display: flex;
    & > p {
        font-family: Aviny;
        font-size: 24px;
        color: #000000;
        margin: 0px 0px 20px;
    }
    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 200px;
        & > a {
            background-color: rgba(247, 222, 215, 0.47);
            border-radius: 50%;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

//all features:
.allFeaturesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    margin: 0px auto 24px;
    direction: rtl;
    @media (max-width: 800px) {
        width: 90%;
        display: grid;
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        row-gap: 20px;
        column-gap: 20px;
    }
    & > button {
        padding: 5px 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: white;
        border: 1px solid black;
        direction: rtl;
        border-radius: 10px;
        cursor: pointer;
        & > p {
            margin: 0px 12px 0px 0px;
            font-family: Aviny;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            @media (max-width: 800px) {
                font-size: 14px;
            }
        }
        & > img {
            width: 24px;
            height: 24px;
            @media (max-width: 800px) {
                width: 20px;
                height: 20px;
            }
        }
    }
    & > button:hover {
        background-color: lightgray;
    }
    & > button:focus {
        outline: none;
    }
}


//radio button:
.radioButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > button {
        width: 25px;
        height: 25px;
        background-color: white;
        border: 1px solid #E4E4E4;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
            width: 12px;
            display: block;
        }
    }
    & > p {
        font-family: IRANYekan;
        font-weight: bold;
        font-size: 16px;
        color: #000000;
        margin: 0px 13px 0px 0px;
    }
}