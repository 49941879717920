.footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0px 0px;
    direction: rtl;
    background-image: url('../../assets/footerBackground.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media (max-width: 800px) {
        padding: 40px 10px 0px;
    }
    & > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        margin: 0px auto 14px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            & > a > p {
                font-weight: bold;
                font-size: 16px;
                line-height: 31px;
                color: #000000;
                margin: 0px 0px 24px;
            }
            & > p {
                font-weight: bold;
                font-size: 16px;
                line-height: 31px;
                color: #000000;
                margin: 0px 0px 24px;
            }
            & > p:first-child {
                font-weight: bold;
                font-size: 20px;
                line-height: 41px;
                color: #BD945B;
                margin: 0px 0px 20px;
            }
            & > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                & > a:nth-child(1) > img {
                    margin-left: 10px;
                }
            }
            & > a:last-child {
                font-weight: bold;
                color: black;
                font-size: 22px;
            }
        }
        & > div:nth-child(2) {
            margin-top: 61px;
            @media (max-width: 800px) {
                margin-top: 0px;
            }
        }
        & > div:last-child {
            display: flex;
            flex-direction: column;
            & > p {
                font-weight: bold;
                font-size: 16px;
                line-height: 31px;
                color: #000000;
                margin: 0px 0px 24px;
                max-width: 500px;
            }
            & > p:first-child {
                font-weight: bold;
                font-size: 20px;
                line-height: 41px;
                color: #BD945B;
                margin: 0px 0px 20px;
            }
            & > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                & > a {
                    width: 91px;
                    & > img {
                        width: 100%;
                    }
                }
                & > a:last-child {
                    margin-right: 20px;
                }
            }
        }
    }
    & > p {
        margin: 0px;
        font-weight: bold;
        font-size: 16px;
        line-height: 31px;
        color: #757575;
    }
}