.toomarCardsSwiperContainer {
    padding: 0px 50px;
    @media (max-width: 800px) {
        padding: 0px 20px;
    }
}

.toomarCardsSwiperItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    width: 70%;
    margin: 0px auto 80px;
    @media (max-width: 800px) {
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        & > div {
            width: 100%;
            margin-bottom: 35px;
        }
    }
    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        & > img {
            transform: rotate(-22.95deg);
            width: 84px;
            height: 84px;
        }
        & > span {
            font-family: Aviny;
            font-size: 60px;
            color: #000000;
            line-height: 100px;
            @media (max-width: 800px) {
                font-size: 40px;
            }
            & > span {
                color: #B6905D;
            }
        }
        & > p {
            font-weight: bold;
            font-size: 24px;
            text-align: center;
            color: #000000;
            max-width: 410px;
            margin: 10px 0px 24px;
            @media (max-width: 800px) {
                font-size: 16px;
            }
        }
        & > button {
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 13px;
            font-weight: bold;
            font-size: 24px;
            color: #000000;
            background-color: white;
            padding: 12px 50px;
            font-family: iranYekan;
            cursor: pointer;
            @media (max-width: 800px) {
                font-size: 16px;
                padding: 14px 20px;
            }
        }
    }
    & > img {
        width: 590px;
        object-fit: cover;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
}

.toomarCardsSwiper > .swiper-button-next:after {
    color: black;
    cursor: pointer;
    margin-left: 20px;
    @media (max-width: 800px) {
        font-size: 30px;
        margin-left: 10px;
    }
}

.toomarCardsSwiper > .swiper-button-next:hover {
    background-color: rgba(0, 0, 0, 0.1)!important;
}

.toomarCardsSwiper > .swiper-button-prev:hover {
    background-color: rgba(0, 0, 0, 0.1)!important;
}

.toomarCardsSwiper > .swiper-button-prev:after {
    color: black;
    margin-right: 20px;
    @media (max-width: 800px) {
        font-size: 30px;
        margin-right: 10px;
    }
}

.toomarCardsSwiper > .swiper-pagination > .swiper-pagination-bullet-active {
    background-color: #E45803;
}

.toomarCardsSwiper > div > .swiper-slide > a > div {
    width: 70%;
}

.hottetsOfToomarContainer {
    padding: 0px 100px;
    @media (max-width: 800px) {
        padding: 0px 20px;
    }
}

.topFeatureOfToomarContainer {
    background-image: url('../../assets/topFeatureBg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    direction: rtl;
    padding: 40px 120px;
    @media (max-width: 800px) {
        padding: 40px 20px;
    }
    & > div {
        display: flex;
        flex-direction: column;
        & > p {
            font-family: katibeh;
            font-size: 36px;
            line-height: 56px;
            color: #BD945B;
            margin: 0px 0px 11px;
            @media (max-width: 800px) {
                font-size: 24px;
            }
            
        }
        & > span {
            font-family: katibeh;
            font-size: 24px;
            line-height: 38px;
            color: #000000;
            @media (max-width: 800px) {
                font-size: 16px;
            }
        }
        & > .leftSpan {
            text-align: left;
            margin: 20px 0px 25px 0px;
        }
        & > a > button {
            border: 1px solid #E15A04;
            box-sizing: border-box;
            border-radius: 10px;
            width: 100%;
            font-family: IRANYekan;
            font-weight: bold;
            font-size: 24px;
            line-height: 41px;
            color: #D45A07;
            background-color: white;
            padding: 14px 100px;
            cursor: pointer;
            @media (max-width: 800px) {
                padding: 14px 20px;
                font-size: 16px;
            }
        }
        & > a > button:hover {
            background-color: #E15A04;
            color: white;
        }
    }
    & > img {
        width: 423px;
        height: 423px;
        border-radius: 50%;
        @media (max-width: 800px) {
            width: 30%;
            display: none;
        }
    }
}

.bestSellingContainer {
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, rgba(255, 192, 174, 0.11) 0%, rgba(255, 228, 220, 0.0462) 44.27%, rgba(255, 229, 222, 0.11) 97.4%);
    align-items: center;
    direction: rtl;
    padding: 60px 50px;
    @media (max-width: 800px) {
        padding: 40px 20px;
        justify-content: space-between;
    }
    & > div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20%;
        @media (max-width: 800px) {
            width: 35%;
            margin-left: 8px;
        }
        & > p:first-child {
            font-family: Aviny;
            font-size: 48px;
            color: #B6905D;
            margin: 0px 0px 20px;
            @media (max-width: 800px) {
                font-size: 24px;
            }
        }
        & > p:last-child {
            font-family: Aviny;
            font-size: 48px;
            margin: 0px;
            @media (max-width: 800px) {
                font-size: 24px;
            }
        }
    }
    & > div:last-child {
        width: 80%;
        @media (max-width: 800px) {
            width: 60%;
            & > div > .swiper-wrapper > div > a > div {
                width: 95%!important;
            }
        }

        .toomarBestSelling {
            overflow: visible;
        }

        .toomarBestSelling > .swiper-button-next:after {
            color: black;
            margin-right: 35px;
            @media (max-width: 800px) {
                font-size: 24px;
                margin-right: 56px;
                background-color: transparent;
            }
        }
        
        .toomarBestSelling > .swiper-button-prev:after {
            color: black;
            margin-left: 35px;
            @media (max-width: 800px) {
                font-size: 24px;
                margin-left: 56px;
                background-color: transparent;
            }
        }
        // .toomarBestSelling > .swiper-button-next:hover {
        //     background-color: rgba(0, 0, 0, 0.1)!important;
        // }
        
        // .toomarBestSelling > .swiper-button-prev:hover {
        //     background-color: rgba(0, 0, 0, 0.1)!important;
        // }

        .toomarBestSelling > .swiper-scrollbar {
            display: none;
        }

        .toomarBestSelling > .swiper-wrapper > div > div {
            margin-bottom: 0px;
        }
        
        .toomarBestSelling > .swiper-wrapper > div > a > div {
            width: 90%;
            @media (max-width: 800px) {
                width: 100%;
                height: auto;
            }
        }
    }
}

.toomarGalleryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div:last-child {
        background: linear-gradient(180deg, rgba(255, 192, 174, 0.11) 0%, rgba(255, 228, 220, 0.0462) 44.27%, rgba(255, 229, 222, 0.11) 97.4%);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 140px;
        width: calc(100% - 280px);
        @media (max-width: 800px) {
            width: calc(100% - 40px);
            padding: 40px 20px;
            
        }
        & > div:first-child {
            display: flex;
            align-items: center;
            flex-direction: row;
            width: 100%;
            direction: rtl;
            overflow-x: scroll;
            overflow-y: hidden;
            @media (max-width: 800px) {
                padding: 0px;
                width: calc(100%);
                display: grid;
                grid-template-columns: auto auto;
            }
            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 66px;
                cursor: pointer;
                width: 200px;
                height: 100px;
                margin: auto;
                & > .isNotActive {
                    font-weight: bold;
                    font-size: 20px;
                    color: black;
                    margin: 0px 0px 20px;
                    width: 200px;
                    text-align: center;
                    @media (max-width: 800px) {
                        font-size: 16px;
                    }
                }
                & > .isActive {
                    font-weight: bold;
                    font-size: 20px;
                    color: #EABA77;
                    margin: 0px 0px 20px;
                    width: 200px;
                    text-align: center;
                    @media (max-width: 800px) {
                        font-size: 16px;
                    }
                }
                & > img {
                    width: 100%;
                }
                & > p:hover {
                    color: #EABA77;
                }
            }
        }
        & > div:last-child {
            margin-top: 53px;
            display: grid;
            grid-template-columns: 33.33333% 66.66667%;
            row-gap: 40px;
            column-gap: 40px;
            width: 80%;
            direction: rtl;
            @media (max-width: 800px) {
                grid-template-columns: calc(33.33333% - 10px) calc(66.66667% - 10px);
                row-gap: 20px;
                column-gap: 20px;
                width: 100%;
            }
            & > div {
                position: relative;
                & > a > img {
                    width: 100%;
                    display: block;
                    object-fit: cover;
                    height: 100%;
                }
                & > a > div {
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    background: #FFF9F7;
                    & > p {
                        font-family: Aviny;
                        font-size: 36px;
                        color: #000000;
                        margin: 0px;
                        padding: 16px 40px;
                        @media (max-width: 800px) {
                            font-size: 16px;
                            padding: 4px;
                        }
                    }
                }
            }
            
            & > div:nth-child(1) {
                width: 100%;
            }
            & > div:nth-child(2) {
                width: 100%;
            }
            & > div:nth-child(3) {
                width: 100%;
            }
            & > div:nth-child(4) {
                display: grid;
                width: 100%;
                grid-template-columns: calc(50% - 20px) calc(50% - 20px);
                column-gap: 40px;
                row-gap: 40px;
                @media (max-width: 800px) {
                    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
                    row-gap: 20px;
                    column-gap: 20px;
                }
                & > div {
                    position: relative;
                    & > a > img {
                        width: 100%;
                        display: block;
                        object-fit: cover;
                        height: 100%;
                    }
                    & > a > div {
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        background: #FFF9F7;
                        & > p {
                            font-family: Aviny;
                            font-size: 36px;
                            color: #000000;
                            margin: 0px;
                            padding: 16px 40px;
                            @media (max-width: 800px) {
                                font-size: 16px;
                                padding: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.whyItemsContainer {
    & > div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 85%;
        margin: 0px auto;
        direction: rtl;
        @media (max-width: 800px) {
            display: grid;
            grid-template-columns: calc(50% - 20px) calc(50% - 20px);
        }
        & > a {
            width: 16%;
            @media (max-width: 800px) {
                width: 100%;
            }
        }
        & > a > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            cursor: pointer;
            & > img {
                height: 90px;
            }
            @media (max-width: 800px) {
                width: 100%;
            }
            & > p {
                font-size: 24px;
                color: #9D9176;
                font-weight: bold;
                margin: 14px 0px 10px;
                padding-top: 11px;
                border-top: 1px solid #333333;
                font-family: 'Aviny';
            }
            & > span {
                max-width: 255px;
                text-align: center;
                font-size: 20px;
                font-family: 'Aviny';
                color: black;
            }
        }
    }
}

.toomarMagazineContainer {
    padding: 0px 100px;
    @media (max-width: 800px) {
        padding: 0px 20px;
    }
}

.toomarMagazineSwiper > .swiper-button-next:after {
    color: black;
    @media (max-width: 800px) {
        margin-left: 20px;
        font-size: 28px;
    }
}

.toomarMagazineSwiper > .swiper-button-prev:after {
    color: black;
    @media (max-width: 800px) {
        margin-right: 20px;
        font-size: 28px;
    }
}

// .toomarMagazineSwiper > .swiper-button-next:hover {
//     background-color: rgba(0, 0, 0, 0.1)!important;
// }

// .toomarMagazineSwiper > .swiper-button-prev:hover {
//     background-color: rgba(0, 0, 0, 0.1)!important;
// }

.toomarMagazineItemsContainer {
    display: grid;
    width: 80%;
    grid-template-columns: calc(40% - 20px) calc(60% - 20px);
    margin: auto;
    direction: rtl;
    column-gap: 40px;
    margin-bottom: 10px;
    @media (max-width: 800px) {
        grid-template-columns: 100%;
        margin: auto;
        direction: rtl;
        column-gap: 20px;
    }
    & > .toomarMagazineItemsContainerColumn2 {
        display: grid;
        grid-template-rows: calc(50% - 20px) calc(50% - 20px);
        direction: rtl;
        row-gap: 40px;
    }
}

.toomarMagazineItemRight:hover {
    transform: scaleX(1.05); 
    box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
}

.toomarMagazineItemRight {
    width: 100%;
    position: relative;
    background: #FFFFFF;
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    transition: all .2s ease-in-out; 
    & > img {
        width: 100%;
        height: 363px;
        margin-bottom: 20px;
        border-radius: 8px 8px 0px 0px;
    }
    & > p {
        margin: 0px 13px 15px 0px;
        font-weight: bold;
        font-size: 18px;
        color: #000000;
    }
    & > .goMore {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        cursor: pointer;
        margin-left: 13px;
        height: 100%;
        margin-bottom: 13px;
        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            & > p {
                margin: 0px 0px 0px 20px;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
            }
            & > img {
                width: 34px;
                display: block;
            }
        }
    }
    & > div:last-child {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #C69C61;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        padding: 6px 20px;
        border-radius: 0px 8px 0px 0px;
    }
}

.toomarMagazineItemLeft:hover {
    transform: scaleX(1.05); 
    box-shadow: -2px 10px 9px rgba(0, 0, 0, 0.3);
}

.toomarMagazineItemLeft {
    width: 100%;
    position: relative;
    background: #FFFFFF;
    box-shadow: -2px 10px 9px rgba(228, 228, 228, 0.47);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    padding: 0px;
    height: 100%;
    transition: all .2s ease-in-out; 
    & > img {
        width: 288px;
        height: 210px;
        object-fit: cover;
        border-radius: 0px 8px 0px 0px;
    }
    & > .detailsInLeft {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 288px);
        & > p {
            margin: 0px 20px;
            font-weight: bold;
            font-size: 18px;
            color: #000000;
            width: 90%;
        }
        & > .goMore {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            margin-bottom: 13px;
            margin-left: 13px;
            & > p {
                margin: 0px 0px 0px 20px;
                font-weight: bold;
                font-size: 18px;
                color: #000000;
            }
            & > img {
                width: 34px;
                display: block;
            }
        }
    }
    & > div:last-child {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #C69C61;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        padding: 6px 20px;
        border-radius: 0px 8px 0px 0px;
    }
}

.repetitiveQuestionsContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    @media (max-width: 800px) {
        width: calc(100% - 40px);
    }
    & > a > button {
        width: 256px;
        height: 76px;
        font-weight: bold;
        font-size: 24px;
        line-height: 41px;
        color: #000000;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 10px;
        font-family: iranYekan;
        background-color: white;
        cursor: pointer;
        @media (max-width: 800px) {
            width: 80%;
            margin: auto;
            font-size: 20px;
        }
    }
}

.takePhotoContainer {
    width: 100%;
    position: relative;
    cursor: pointer;
    margin-top: 50px;
}

.loadingImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}